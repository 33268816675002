<template>
    <div class="m-5">
        <div class="grid grid-cols-2 gap-5 mb-5">
            <div class="col-span-1 rounded-sm bg-gray-800 shadow-sm p-5">
                <canvas id="debit"></canvas>
            </div>
            <div class="col-span-1 rounded-sm bg-gray-800 shadow-sm p-5">
                <canvas id="ph"></canvas>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-5 mb-5">
            <div class="col-span-1 rounded-sm bg-gray-800 shadow-sm p-5">
                <canvas id="tmp"></canvas>
            </div>
            <div class="col-span-1 rounded-sm bg-gray-800 shadow-sm p-5">
                <canvas id="cod"></canvas>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-5">
            <div class="col-span-1 rounded-sm bg-gray-800 shadow-sm p-5">
                <canvas id="tss"></canvas>
            </div>
            <div class="col-span-1 rounded-sm bg-gray-800 shadow-sm p-5">
                <canvas id="nh3n"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'
import axios from 'axios'
export default {
    data () {
        return { 
            ph: [],
            tmp: [],
            cod: [],
            tss: [],
            nh3n: [],
            debit: [],
            time: []
        }
    },
    mounted () {
        let app = this
        this.getData()
        var ctxDebit = document.getElementById('debit')
        window.myDebit =  new Chart(ctxDebit, {
            type: 'bar',
            data: {
                labels: app.time,
				datasets: [{
                    label: 'DEBIT',
					data: app.debit,
					backgroundColor: 'rgb(5,182,212, 0.7)',
					fill: true
				}]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: 'white'
                        },
                        position: 'top',
                    },
                },
                scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(156,163,168)',
							display: true
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
            },
        })

        var ctxPh = document.getElementById('ph')
        window.myPh = new Chart(ctxPh, {
            type: 'bar',
            data: {
                labels: app.time,
				datasets: [{
                    label: 'pH METER',
					data: app.ph,
					backgroundColor: 'rgb(16,185,140, 0.7)',
					fill: true
				}]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: 'white'
                        },
                        position: 'top',
                    },
                },
                scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(156,163,168)',
							display: true
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
            },
        })

        var ctxTmp = document.getElementById('tmp')
        window.myTmp = new Chart(ctxTmp, {
            type: 'bar',
            data: {
                labels: app.time,
				datasets: [{
                    label: 'TEMPERATUR',
					data: app.tmp,
					backgroundColor: 'rgb(239,68,102, 0.7)',
					fill: true
				}]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: 'white'
                        },
                        position: 'top',
                    },
                },
                scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(156,163,168)',
							display: true
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
            },
        })

        var ctxCod = document.getElementById('cod')
        window.myCod = new Chart(ctxCod, {
            type: 'bar',
            data: {
                labels: app.time,
				datasets: [{
                    label: 'COD',
					data: app.cod,
					backgroundColor: 'rgb(245,158,10, 0.7)',
					fill: true
				}]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: 'white'
                        },
                        position: 'top',
                    },
                },
                scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(156,163,168)',
							display: true
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
            },
        })

        var ctxTss = document.getElementById('tss')
        window.myTss = new Chart(ctxTss, {
            type: 'bar',
            data: {
                labels: app.time,
				datasets: [{
                    label: 'TSS',
					data: app.tss,
					backgroundColor: 'rgb(168,85,247, 0.7)',
					fill: true
				}]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: 'white'
                        },
                        position: 'top',
                    },
                },
                scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(156,163,168)',
							display: true
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
            },
        })

        var ctxNh3n = document.getElementById('nh3n')
        window.myNh3n = new Chart(ctxNh3n, {
            type: 'bar',
            data: {
                labels: app.time,
				datasets: [{
                    label: 'NH3N',
					data: app.nh3n,
					backgroundColor: 'rgb(58,133,247, 0.7)',
					fill: true
				}]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: 'white'
                        },
                        position: 'top',
                    },
                },
                scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(156,163,168)',
							display: true
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
            },
        })
    },
    methods: {
        getData () {
            axios.get('api/statistics')
            .then((res) => {
                let modbus = res.data.reverse()
                modbus.forEach(el => {
                    let date = new Date(el.added_at)
                    let h = date.getHours()
                    let m = date.getMinutes()
                    let s = date.getSeconds()
                    this.time.push(`${h}:${m}:${s}`)
                    this.ph.push(el.ph)
                    this.tmp.push(el.tmp)
                    this.cod.push(el.cod)
                    this.tss.push(el.tss)
                    this.nh3n.push(el.nh3n)
                    this.debit.push(el.debit)
                });
                this.$nextTick(() => {
                    window.myDebit.update()
                    window.myPh.update()
                    window.myTmp.update()
                    window.myCod.update()
                    window.myTss.update()
                    window.myNh3n.update()
                })
            })
        }
    }
}
</script>