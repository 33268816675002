<template>
    <div class="fixed flex flex-wrap justify-between px-5 items-stretch bg-gray-900 z-10 inset-x-0 text-gray-300">
		<div class="-ml-5 mr-5 bg-gray-800 text-gray-50 flex items-center border-b border-gray-700">
			<div class="ml-5 py-2.5 w-60">
				<div class="flex items-center">
					<a href="" class="iniline-block">
						<img src="@/assets/logo_satcomm.png" class="block h-4" alt="">
					</a>
				</div>
			</div>
		</div>
		<div class="flex flex-grow items-center px-5 border-b border-gray-800 -mx-5">
			<ul class="flex felx-row">
				<li>
					<div class="flex items-center h-12">
						
					</div>
				</li>
			</ul>
			<ul class="flex felx-row items-center ml-auto">
				<li class="relative">
					<dropdown :right="true">
						<a @click="onRead" href="#" class="flex items-center h-12 px-4 hover:bg-gray-700">
							<div class=	"relative">
								<i class="icon-bell2"></i>
								<div v-if="unread"  class="absolute rounded-full h-5 w-5 min-w-min bg-red-500 bg-opacity-75 -right-3 -top-3 flex items-center justify-center">
									<div class="text-white font-semibold" style="font-size: 10px">{{unread}}</div>
								</div>
							</div>
						</a>
						<template #dropdown>
							<div class="w-72">
								<div class="px-5 py-3 font-semibold border-b border-gray-500 shadow-lg">Notifikasi</div>
								<ul v-if="notifications.length > 0" class="max-h-96 overflow-y-scroll divide-y divide-gray-500">
									<li v-for="(notif, i) in notifications" :key="i">
										<router-link to="/early" class="px-5 py-3 inline-block hover:bg-gray-600 w-full">
											<div class="flex items-center justify-between mb-1">
												<div class="font-semibold">{{notif['data'].parameter}}</div>
												<div class="font-semibold">{{notif['data'].value}}</div>

											</div>
											{{notif['data'].description}}
											<div class="text-right text-xs text-gray-400 mt-1">{{notif['data'].added_at}}</div>
										</router-link>
									</li>
								</ul>
								<ul v-else>
									<li class="p-5 flex items-center justify-center">
										<div>BELUM ADA NOTIFIKASI</div>
									</li>
								</ul>
								<div class="px-5 border-t border-gray-500 py-3 flex items-center justify-between">
									<div v-if="notifications.length > 0" class="ml-auto">
										<a @click="deleteAll" href="#" class="text-gray-400 hover:text-gray-500">Hapus semua</a>
									</div>
								</div>
							</div>
						</template>
					</dropdown>
				</li>
				
				<li class="border-l border-gray-700 py-3 mx-2"></li>
				<li class="relative">
					<dropdown :right="true">
						<a v-if="user" href="#" class="relative px-4 flex items-center h-12 user hover:bg-gray-700">
							{{user.name}} <i class="icon-arrow-down22 ml-3"></i>
						</a>
						<template #dropdown>
							<div class="w-40 text-gray-400">
								<router-link to="/account" class="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-600"><i class="icon-user mr-4"></i>Akun saya</router-link>
								<a @click.prevent="logout" href="#" class="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-600"><i class="icon-switch2 mr-4"></i>Keluar</a>
							</div>
						</template>
					</dropdown>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import Dropdown from './Dropdown.vue'
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import { db } from '../db'
const sound = require('@/assets/notif.mp3')
export default {
    components: {
        Dropdown
    },
	data () {
		return {
			notifications: '',
			unread: '',
			notif: ''
		}
	},
	computed: {
		...mapGetters({
			user: 'auth/user'
		})
	},
	mounted () {
		if(this.user) {
			this.getData()
		}
	},
	firebase: {
		notif: db.ref('iha/notif')
	},
	methods: {
		...mapActions({
			signout: 'auth/logout'
		}),
		async logout() {
			await this.signout()
			this.$router.push('/login')
		},
		getData () {
			axios.get('api/notifications')
			.then((res) => {
				this.unread = res.data.count
				this.notifications = res.data.data
				if(res.data.count > 0) {
					let notif = new Audio(sound)
					notif.play()
				}
			})
		},
		onRead () {
			if(this.unread) {
				this.unread = ''
				axios.post('api/notifications/read')
				.then(() => {
					this.unread = ''
				})
			}
		},
		deleteAll () {
			if(this.notifications.length > 0) {
				axios.post('api/notifications/delete')
				.then(() => {
					this.notifications = ''
				})
			}
		}
	},
	watch: {
		user () {
			if(this.user) {
				this.getData()
			}
		},
		notif () {
			if(this.user) {
				this.getData()
			}
		}
	}
}
</script>