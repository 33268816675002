import axios from "axios"

export default {
    namespaced: true,
    state: {
        authenticated: false,
        user: null,
        company: null,
    },
    getters: {
        authenticated (state) {
            return state.authenticated
        },

        user (state) {
            return state.user
        },

        company (state) {
            return state.company
        }

    },
    mutations: {
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value
        },

        SET_USER(state, value) {
            state.user = value
        },

        SET_COMPANY(state, value) {
            state.company = value
        }
    },
    actions: {
        async login({dispatch}, credential) {
            await axios.get('sanctum/csrf-cookie')
            await axios.post('login', credential)
            dispatch('me')
        },

        async me({commit}) {
            try {
                commit('SET_AUTHENTICATED', true)
                
                let response = await axios.get('api/me')
                let company = await axios.get('api/perusahaan')

                commit('SET_USER', response.data)
                commit('SET_COMPANY', company.data.nama_perusahaan)
            } catch (error) {
                commit('SET_AUTHENTICATED', false)
                commit('SET_USER', null)   
                commit('SET_COMPANY', null)
            }
        },

        async logout({commit}) {
            await axios.post('logout')
            commit('SET_AUTHENTICATED', false)
            commit('SET_USER', null)  
            commit('SET_COMPANY', null)
        },
        update ({commit}, data) {
            commit('SET_USER', data)
        },
        expired ({commit}) {
            commit('SET_AUTHENTICATED', false)
            commit('SET_USER', null)  
            commit('SET_COMPANY', null)
        }

        
    }
}