<template>
    <div class="p-5">
        <div class="grid grid-cols-2 gap-5">
            <div class="col-span-1 bg-white bg-opacity-75 p-5 rounded-sm border vld-parent">
                <loading :active.sync="userLoading" 
                    :is-full-page="false">
                </loading>
                <div class="mb-5 font-medium">INFO PENGGUNA</div>
                <form action="" @submit.prevent="onSubmitUser">
                    <div class="mb-5">
                        <label for="">Nama Lengkap</label>
                        <input v-model="formUser.name" @keydown="errorUser.name=''" type="text" class="h-9 w-full bg-gray-50 bg-opacity-50 border rounded-sm px-3 focus:outline-none mt-2">
                        <span class="text-red-500">{{errorUser.name}}</span>
                    </div>
                    <div class="mb-5">
                        <label for="">Email</label>
                        <input v-model="formUser.email" @keydown="errorUser.email=''" type="text" class="h-9 w-full bg-gray-50 bg-opacity-50 border rounded-sm px-3 focus:outline-none mt-2">
                        <span class="text-red-500">{{errorUser.email}}</span>
                    </div>
                    <div class="text-right">
                        <button class="h-9 bg-blue-500 rounded-sm px-5 focus:outline-none text-gray-100 hover:bg-blue-600">Update</button>
                    </div>
                </form>
            </div>
            <div class="col-span-1 bg-white bg-opacity-75 p-5 rounded-sm border vld-parent">
                <loading :active.sync="passwordLoading" 
                    :is-full-page="false">
                </loading>
                <div class="mb-5 font-medium">GANTI PASSWORD</div>
                <form action="" @submit.prevent="onSubmitPassword">
                    <div class="mb-5">
                        <label for="">Passwor Baru</label>
                        <input v-model="formPassword.password" @keydown="errorPassword.password=''" type="password" class="h-9 w-full bg-gray-50 bg-opacity-50 border rounded-sm px-3 focus:outline-none mt-2" placeholder="isi dengan password barus">
                        <span class="text-red-500">{{errorPassword.password}}</span>
                    </div>
                    <div class="mb-5">
                        <label for="">Ulangi Password</label>
                        <input v-model="formPassword.confirm_password" @keydown="errorPassword.confirm_password=''" type="password" class="h-9 w-full bg-gray-50 bg-opacity-50 border rounded-sm px-3 focus:outline-none mt-2" placeholder="ketik ulang password baru">
                        <span class="text-red-500">{{errorPassword.confirm_password}}</span>
                    </div>
                    <div class="text-right">
                        <button class="h-9 bg-blue-500 rounded-sm px-5 focus:outline-none text-gray-100 hover:bg-blue-600">Update</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
    components: {
        Loading
    },
    data () {
        return {
            userLoading: false,
            passwordLoading: false,
            formUser: {
                id: '',
                name: '',
                email: ''
            },
            errorUser: {
                name: '',
                email: ''
            },
            formPassword: {
                id: '',
                password: '',
                confirm_password: '',
            },
            errorPassword: {
                password: '',
                confirm_password: ''
            }
        }
    },
    mounted () {
        this.formUser.id = this.user.id
        this.formUser.name = this.user.name
        this.formUser.email = this.user.email
        this.formPassword.id = this.user.id
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        })
    },
    methods: {
        onSubmitUser () {
            if(!this.formUser.name) {
                this.errorUser.name = 'Nama lengkap harus diisi'
            }
            if(!this.formUser.email) {
                this.errorUser.email = 'Alamat email harus diisi'
            }
            if(this.formUser.name && this.formUser.email) {
                this.userLoading = true
                axios.post('api/account/update', this.formUser)
                .then((res) => {
                    this.userLoading = false
                    this.$store.dispatch('auth/update', res.data)
                })
            }
        },
        onSubmitPassword () {
            if(!this.formPassword.password) {
                this.errorPassword.password = 'Password harus diisi'
            }
            if(!this.formPassword.confirm_password) {
                this.errorPassword.confirm_password = 'Password harus diulangi'
            }
            if(this.formPassword.password != this.formPassword.confirm_password) {
                this.errorPassword.confirm_password = 'Password tidak sama'
                this.errorPassword.password = 'Password tidak sama'
            }

            if(this.formPassword.password && this.formPassword.confirm_password)
            {
                this.passwordLoading = true
                axios.post('api/account/password', this.formPassword)
                .then(() => {
                    this.passwordLoading = false
                    this.formPassword.password = ''
                    this.formPassword.confirm_password = ''
                })
            }
        }
    }

}
</script>